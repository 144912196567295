.loading__masque {
  background: white;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  opacity: 1;
}

[id^=NotiflixLoadingWrap] > div[class*=-icon] svg {
  fill: white;
  stroke: var(--color2-500);
  height: 70px !important;
}

.hide-loading {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.7s;
}

[id^=NotiflixLoadingWrap].hide-loading,
.loading__masque.hide-loading {
  z-index: -50 !important;
}